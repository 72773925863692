import React from "react"
import { navigate, useStaticQuery, graphql } from "gatsby"
import {
  useCourseStore,
  getCourseNavigation,
  getCourseProgress,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Column from "../../../../../components/column"
import Columns from "../../../../../components/columns"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Grid from "../../../../../components/grid"
import Heading from "../../../../../components/heading"
import Image from "../../../../../components/image"
import Note from "../../../../../components/note"
import Seo from "../../../../../components/seo"
import Select from "../../../../../components/select"
import Stack from "../../../../../components/stack"
import Steps from "../../../../../components/steps"
import Text from "../../../../../components/text"
import Textarea from "../../../../../components/textarea"

const taskProps = {
  courseId: "hoelderlins-reisen",
  chapterId: "01-friedrich-hoelderlin",
  taskId: "wie-sah-hoelderlin-aus",
}

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation(taskProps)
  const progress = getCourseProgress(taskProps)

  const data = useStaticQuery(graphql`
    query WieSahHoelderlinBeschreibungQuery {
      portrait: file(relativePath: { eq: "kurse/portrait-hoelderlin.png" }) {
        ...mediumImage
      }
    }
  `)

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          ...taskProps,
          taskId: "beschreibung",
        })
        navigate(
          "/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/wie-sah-hoelderlin-aus/zusammenfassung"
        )
      }}
      navigation={navigation}
      progress={progress}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Wie sah Hölderlin aus? " />
      <Stack>
        <Stack space={3}>
          <Heading as="h1" level={2}>
            Wie sah Hölderlin aus?
          </Heading>
        </Stack>
        <Grid columns={[1, 2]}>
          <Image
            image={data.portrait.childImageSharp.gatsbyImageData}
            alt="Porträt"
            caption="So sah Friedrich Hölderlin als Jugendlicher aus."
          />
          <Stack>
            <Note variant="task">
              Fertige eine kurze Beschreibung von Hölderlin an. Vervollständige
              die Sätze, indem du dir im Auswahlfeld dahinter eine passende
              Beschreibung aussuchst.
            </Note>
            <Steps>
              {({ increaseStepTo }) => (
                <Stack>
                  <Columns alignY="center" collapse={[true, true, false]}>
                    <Column>
                      <Text size={[2, 2, 3, 3, 4]}>Seine Augen</Text>
                    </Column>
                    <Column>
                      <Select
                        id="augen"
                        name="augen"
                        defaultValue=""
                        onChange={() => {
                          increaseStepTo({ step: 1 })
                        }}
                      >
                        <option value="">-</option>
                        <option value="Seine Augen sehen geradeaus">
                          sehen geradeaus
                        </option>
                        <option value="Seine Augen blicken ins Ferne">
                          blicken ins Ferne
                        </option>
                        <option value="Seine Augen wirken träumerisch">
                          wirken träumerisch
                        </option>
                        <option value="wirken müde">wirken müde</option>
                      </Select>
                    </Column>
                  </Columns>
                  <Columns alignY="center" collapse={[true, true, false]}>
                    <Column>
                      <Text size={[2, 2, 3, 3, 4]}>Seine Stirn ist</Text>
                    </Column>
                    <Column>
                      <Select
                        id="stirn"
                        name="stirn"
                        defaultValue=""
                        onChange={() => {
                          increaseStepTo({ step: 2 })
                        }}
                      >
                        <option value="">-</option>
                        <option value="Seine Stirn ist hoch">hoch</option>
                        <option value="Seine Stirn ist gebogen">gebogen</option>
                        <option value="Seine Stirn ist kahl">kahl</option>
                      </Select>
                    </Column>
                  </Columns>
                  <Columns alignY="center" collapse={[true, true, false]}>
                    <Column>
                      <Text size={[2, 2, 3, 3, 4]}>Seine Haare sind</Text>
                    </Column>
                    <Column>
                      <Select
                        id="haare"
                        name="haare"
                        defaultValue=""
                        onChange={() => {
                          increaseStepTo({ step: 3 })
                        }}
                      >
                        <option value="">-</option>
                        <option value="Seine Haare sind lang">lang</option>
                        <option value="Seine Haare sind hell">hell</option>
                        <option value="Seine Haare sind lockig">lockig</option>
                        <option value="Seine Haare sind gepflegt">
                          gepflegt
                        </option>
                        <option value="Seine Haare sind ungepflegt">
                          ungepflegt
                        </option>
                        <option value="Seine Haare sind strubbelig">
                          strubbelig
                        </option>
                      </Select>
                    </Column>
                  </Columns>
                  <Columns alignY="center" collapse={[true, true, false]}>
                    <Column>
                      <Text size={[2, 2, 3, 3, 4]}>Seine Nase ist</Text>
                    </Column>
                    <Column>
                      <Select
                        id="nase"
                        name="nase"
                        defaultValue=""
                        onChange={() => {
                          increaseStepTo({ step: 4 })
                        }}
                      >
                        <option value="">-</option>
                        <option value="Seine Nase ist lang">lang</option>
                        <option value="Seine Nase ist kurz">kurz</option>
                        <option value="Seine Nase ist stupsig">stupsig</option>
                        <option value="Seine Nase ist schmal">schmal</option>
                        <option value="Seine Nase ist krumm">krumm</option>
                      </Select>
                    </Column>
                  </Columns>
                  <Columns alignY="center" collapse={[true, true, false]}>
                    <Column>
                      <Text size={[2, 2, 3, 3, 4]}>Seine Lippen sind</Text>
                    </Column>
                    <Column>
                      <Select
                        id="lippen"
                        name="lippen"
                        defaultValue=""
                        onChange={() => {
                          increaseStepTo({ step: 5 })
                        }}
                      >
                        <option value="">-</option>
                        <option value="Seine Lippen sind fein">fein</option>
                        <option value="Seine Lippen sind rau">rau</option>
                        <option value="Seine Lippen sind schwulstig">
                          schwulstig
                        </option>
                        <option value="Seine Lippen sind zierlich">
                          zierlich
                        </option>
                        <option value="Seine Lippen sind spitz">spitz</option>
                        <option value="Seine Lippen sind gebogen">
                          gebogen
                        </option>
                      </Select>
                    </Column>
                  </Columns>
                  <Columns alignY="center" collapse={[true, true, false]}>
                    <Column>
                      <Text size={[2, 2, 3, 3, 4]}>Er trägt</Text>
                    </Column>
                    <Column>
                      <Select
                        id="traegt"
                        name="traegt"
                        defaultValue=""
                        onChange={() => {
                          increaseStepTo({ step: 6 })
                        }}
                      >
                        <option value="">-</option>
                        <option value="Er trägt eine Bluse mit Rüschen">
                          eine Bluse mit Rüschen
                        </option>
                        <option value="Er trägt ein Hemd mit weitem Kragen">
                          ein Hemd mit weitem Kragen
                        </option>
                        <option value="Er trägt ein Hemd mit vielen Falten">
                          ein Hemd mit vielen Falten
                        </option>
                      </Select>
                    </Column>
                  </Columns>
                  <Columns alignY="center" collapse={[true, true, false]}>
                    <Column>
                      <Text size={[2, 2, 3, 3, 4]}>Darüber hat er</Text>
                    </Column>
                    <Column>
                      <Select
                        id="kleidung"
                        name="kleidung"
                        defaultValue=""
                        onChange={() => {
                          increaseStepTo({ step: 7 })
                        }}
                      >
                        <option value="">-</option>
                        <option value="Darüber hat er eine Jacke">
                          eine Jacke
                        </option>
                        <option value="Darüber hat er ein Jackett">
                          ein Jackett
                        </option>
                        <option value="Darüber hat er ein dunkles Oberteil">
                          ein dunkles Oberteil
                        </option>
                      </Select>
                    </Column>
                  </Columns>
                  <Columns alignY="center" collapse={[true, true, false]}>
                    <Column>
                      <Text size={[2, 2, 3, 3, 4]}>Seine Kleidung wirkt</Text>
                    </Column>
                    <Column>
                      <Select
                        id="kleidungwirkt"
                        name="kleidungwirkt"
                        defaultValue=""
                        onChange={() => {
                          increaseStepTo({ step: 8 })
                        }}
                      >
                        <option value="">-</option>
                        <option value="Seine Kleidung wirkt fein">fein</option>
                        <option value="Seine Kleidung wirkt stilvoll">
                          stilvoll
                        </option>
                        <option value="Seine Kleidung wirkt erwachsen">
                          erwachsen
                        </option>
                        <option value="Seine Kleidung wirkt merkwürdig">
                          merkwürdig
                        </option>
                        <option value="Seine Kleidung wirkt zu groß">
                          zu groß
                        </option>
                        <option value="Seine Kleidung wirkt verlottert">
                          verlottert
                        </option>
                        <option value="Seine Kleidung wirkt ungepflegt">
                          ungepflegt
                        </option>
                      </Select>
                    </Column>
                  </Columns>
                  <Textarea
                    label="Gibt es sonst noch etwas, was dir an ihm auffällt?"
                    placeholder="Hier kannst du deine eigenen Beobachtungen eintragen"
                    id="sontiges"
                    name="sontiges"
                    rows={5}
                  />
                </Stack>
              )}
            </Steps>
          </Stack>
        </Grid>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
